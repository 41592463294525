import { defineStore } from "pinia";
import { useRouter } from "vue-router";
import Tracking from "~/utils/tracking";
export const useUiStore = defineStore("ui", {
  state: () => ({
    _appDownloadOpen: false,
    _navbarMenuOpen: false,
    _changeAvailabilityModalOpen: false,
  }),
  getters: {
    appDownloadOpen: (state) => {
      return state._appDownloadOpen;
    },
    navbarMenuOpen: (state) => {
      return state._navbarMenuOpen;
    },
    changeAvailabilityModalOpen: (state) => {
      return state._changeAvailabilityModalOpen;
    },
  },
  actions: {
    openAppDownload(source) {
      const tracking = Tracking.getInstance();

      this._appDownloadOpen = true;
    },
    closeAppDownload() {
      this._appDownloadOpen = false;
    },
    openNavbarMenu() {
      this._navbarMenuOpen = true;
    },
    closeNavbarMenu() {
      this._navbarMenuOpen = false;
    },
    openChangeAvailabilityModal() {
      this._changeAvailabilityModalOpen = true;
    },
    closeChangeAvailabilityModal() {
      this._changeAvailabilityModalOpen = false;
    },
  },
});
